<template>
  <div class="card product-details-card">
    <!-- Page Header Inside Card -->
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240)"
      :title="product.name"
      class="card-header"
      @back="goBack"
    >
    </a-page-header>

    <div v-if="isLoading" class="loading-spinner">
      <a-spin />
    </div>
    <div v-else-if="error" class="error-message">
      <p>{{ error }}</p>
    </div>
    <div v-else class="product-details">
      <div class="product-image-wrapper">
        <img :src="product.coverPicture" alt="Product Image" class="product-image" />
      </div>

      <div class="product-info">
        <h2>{{ product.name }}</h2>
        <p><strong>{{ $t("product.description") }}:</strong> <span v-html="product.description"></span></p>
        <p><strong>{{ $t("product.price") }}:</strong> {{ product.price }} {{ $t("product.currency") }}</p>
        <p class="availability"><strong>{{ $t("product.availability") }}:</strong>
          <a-tag :color="getTagColor1(product.availability)">
            {{ getTagText1(product.availability) }}
          </a-tag>
        </p>
        <p><strong>{{ $t("product.quantity") }}:</strong> {{ product.quantity }}</p>
        <p><strong>{{ $t("product.lastUpdate") }}:</strong> {{ formattedDate }}</p>
      </div>
    </div>

    <h3 class="associated-commands-header">{{ $t("command.associatedCommands") }}</h3>
    <a-table
      :columns="commandColumns"
      :data-source="paginatedCommands"
      :pagination="paginationConfig"
      :rowKey="'_id'"
      class="associated-commands-table"
    >
      <template slot="etat" slot-scope="text, record">
        <a-tag :color="getTagColor(record.state)">
          {{ getStateLabel(record.state) }}
        </a-tag>
      </template>
      <template slot="date" slot-scope="text, record">
        {{ formatCommandDate(record.createdAt) }}
      </template>
      <!-- Action buttons (Edit, View, Delete) -->
      <span slot="operation" slot-scope="text, record">
        <span class="button-spacing">
          <a-button @click="() => $router.push('/command/' + record._id)">
            <a-icon type="eye" /> {{ $t("action.voir") }}
          </a-button>
        </span>
        <span class="button-spacing">
          <a-button type="primary" @click="showEditModal(record)">
            <a-icon type="edit" />{{ $t("action.modifier") }}
          </a-button>
        </span>
        <span class="button-spacing">
          <a-popconfirm :title="$t('requis.supp')" @confirm="deleteCommand(record._id)">
            <a-button type="danger">
              <a-icon type="delete" />  
              <span style="margin-left: 8px;">{{ $t("action.annuler") }}</span>
            </a-button>
          </a-popconfirm>
        </span>
      </span>
      
    </a-table>

    <!-- Edit Modal -->
    <a-modal
      v-model="isEditModalVisible"
      :title="$t('action.modifier')"
      @ok="updateCommand"
      @cancel="closeEditModal"
    >
      <div>
        <label for="state">{{ $t('command.state') }}:</label>
        <a-select v-model="selectedState" style="width: 100%" placeholder="Select state">
          <a-select-option value="pending">{{ $t('command.pending') }}</a-select-option>
          <a-select-option value="in progress">{{ $t('command.inProgress') }}</a-select-option>
          <a-select-option value="validated">{{ $t('command.validated') }}</a-select-option>
        </a-select>
      </div>
      <div style="margin-top: 16px;">
        <label for="note">{{ $t('command.note') }}:</label>
        <a-textarea
          v-model="note"
          rows="4"
          placeholder="Enter note for the command"
        />
      </div>
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
import apiClient from "@/services/axios";

export default {
  name: "ProductDetails",
  data() {
    return {
      product: {},
      associatedCommands: [],
      isLoading: false,
      error: null,
      isEditModalVisible: false,
      selectedCommand: null,
      selectedState: "",
      note: "",
      currentPage: 1,
      pageSize: 5, 
    };
  },
  computed: {
    commandColumns() {
      return [
        {
          title: this.$t("product.reference"),
          dataIndex: "reference",
          key: "reference",
        },
        {
          title: this.$t("command.date"),
          dataIndex: "createdAt",
          key: "createdAt",
          customRender: (text) => {
   
   return text ? moment(text).format("DD/MM/YYYY HH:mm") : this.$t("product.noUpdate");
 },
        },
        {
          title: this.$t("command.state"),
          dataIndex: "state",
          key: "state",
          scopedSlots: { customRender: "etat" },
        },
        {
          title: this.$t("action.actions"),
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ];
    },
    paginatedCommands() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.associatedCommands.slice(start, end);
    },
    paginationConfig() {
      return {
        current: this.currentPage,
        pageSize: this.pageSize,
        total: this.associatedCommands.length,
        onChange: this.handlePageChange,
      };
    },
    formattedDate() {
      return this.product.updatedAt
        ? moment(this.product.updatedAt).format("DD/MM/YYYY HH:mm")
        : "";
    },
  },
  methods: {
    async fetchProductDetails(productId) {
      this.isLoading = true;
      this.error = null;
      try {
        const productResponse = await apiClient.get(`/marketplace/product/${productId}`);
        this.product = productResponse.data.product;
        this.associatedCommands = productResponse.data.relatedCommands.map(command => {
          if (command.state === "in progress") {
            command.state = "inProgress";
          }
          return command;
        });
      } catch (error) {
        console.error("Error fetching product details or commands:", error);
        this.error = this.$t("errors.fetchDataFailed");
      } finally {
        this.isLoading = false;
      }
    },
    formatCommandDate(date) {
      return date ? moment(date).format("DD/MM/YYYY HH:mm") : "";
    },
    handlePageChange(page) {
      this.currentPage = page;
    },
    getTagText1(availability) {
      switch (availability) {
        case 'available':
          return this.$t("product.available");
        case 'soon':
          return this.$t("product.comingSoon");
        case 'out':
          return this.$t("product.outOfStock");
        default:
          return this.$t("product.unavailable");
      }
    },
    getTagColor1(availability) {
      switch (availability) {
        case 'available':
          return 'green';
        case 'soon':
          return 'orange';
        case 'out':
          return 'red';
        default:
          return 'gray';
      }
    },
    getTagColor(state) {
      switch (state) {
        case "pending":
          return "orange";
        case "inProgress":
          return "blue";
        case "validated":
          return "green";
        case "cancelled":
          return "red";
        default:
          return "gray";
      }
    },
    getStateLabel(state) {
      switch (state) {
        case "pending":
          return this.$t("command.pending");
        case "inProgress":
          return this.$t("command.inProgress");
        case "validated":
          return this.$t("command.validated");
        case "cancelled":
          return this.$t("command.cancelled");
      }
    },
    showEditModal(record) {
      this.selectedCommand = record;
      this.selectedState = record.state;
      this.note = "";
      this.isEditModalVisible = true;
    },
    closeEditModal() {
      this.isEditModalVisible = false;
    },
    updateCommand() {
      const payload = {};
      if (this.selectedState !== this.selectedCommand.state) payload.state = this.selectedState;
      if (this.note.trim()) payload.note = this.note;
      if (!Object.keys(payload).length) {
        this.$message.info(this.$t("no.changes"));
        return;
      }
      apiClient.put(`/marketplace/command/${this.selectedCommand._id}`, payload).then(() => {
        this.$message.success(this.$t("success.commandUpdate"));
        this.isEditModalVisible = false;
        this.fetchProductDetails(this.product._id); 
      }).catch(() => {
        this.$message.error(this.$t("error.commandUpdate"));
      });
    },
    deleteCommand(commandId) {
      this.isLoading = true;
      apiClient
        .delete(`/marketplace/command/${commandId}`)
        .then(() => {
          this.$message.success(this.$t("success.commandSupp"));
          this.fetchProductDetails(this.product._id);
        })
        .catch(() => {
          this.$message.error(this.$t("error.autorisationSupp"));
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    goBack() {
      this.$router.push('/marketPlace');
    },
  },
  created() {
    const productId = this.$route.params.id;
    this.fetchProductDetails(productId);
  },
};
</script>

<style scoped>
.product-details {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.product-image-wrapper {
  flex-shrink: 0;
  margin-left: 20px;
}

.product-image {
  max-width: 200px;
  height: auto;
  object-fit: contain;
}

.product-info {
  flex-grow: 1;
}

.availability {
  display: flex;
  align-items: center;
  gap: 10px; 
}

.associated-commands-table {
  margin-top: 40px; 
  padding: 20px; 
  background-color: #fff; 
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
}

.associated-commands-header {
  margin-left: 20px;
}

.card-header {
  margin-bottom: 20px;
}
.button-spacing {
  margin-right: 30px;
}

</style>
